var $ = require('jquery');

$(document).ready(function(){

    $('a.login-submit').bind('click', function(){
        $('#login-form').submit();
    });

    var shwpwd = $("#show_pwd");
    // $('.show_pwd').children('i.fa').css({color: "#0c4a5e"})

    shwpwd.bind('change',function(e){

        var pwdfield = document.getElementById('password' );
        var showlabel = $('.show_pwd');
        if ($(this)[0].checked === true) {
            pwdfield.type = 'text'
            //$('.show_pwd').children('i.fa-solid').css({color: "#dcdcdc"}).removeClass('fa-eye').addClass('fa-eye-slash');

            showlabel.find('[data-fa-i2svg]').css({color: "#dcdcdc"}).removeClass('fa-eye').addClass('fa-eye-slash')
            showlabel.find('span').text('Hide Password');

        } else {
            pwdfield.type = 'password'
            //$('.show_pwd').children('i.fa-solid').css({color: "#0c4a5e"}).removeClass('fa-eye-slash').addClass('fa-eye');
            showlabel.find('[data-fa-i2svg]').css({color: "#0c4a5e"}).removeClass('fa-eye-slash').addClass('fa-eye');
            showlabel.find('span').text('Show Password');
        }

    });

})
